<template>
  <date-picker class="date-picker" v-model="aDate">
    <template v-slot="{ togglePopover }">
      <div class="form-group" :class="customClass">
        <span class="material-icons" @click="togglePopover()" aria-hidden="true">event</span>
        <input type="text" class="form-control icon-left" :placeholder="label" :value="getReadableDate(aDate)" @click="togglePopover()" @keyup.enter="togglePopover()">
        <span v-if="aDate!==null" class="material-icons right" @click="deselectValue" aria-hidden="true">close</span>
      </div>
    </template>
  </date-picker>

</template>

<script>
  import { DatePicker } from 'v-calendar';
  import { getFilterDate,getReadableDate } from '@/utils/appointment-helpers';

  export default {
    name: 'Custom',
    components: {
      DatePicker
    },
    props: {
      label: {
        type: String,
        default: "Datum"
      },
      customClass: {
        type: String,
        default: "mb10"
      },
      initialValue: {
        type: Date,
        default: null
      },
    },
    data() {
      return {
        aDate: null
      }
    },
    watch: {
      initialValue(value){
        this.aDate=value;
      },
      aDate(newDate) {
        this.$emit('changedDate',newDate);
      }
    },
    created() {
      this.aDate=this.initialValue;
    },
    computed:{
    },
    methods: {
      getReadableDate,
      deselectValue(){
        this.aDate = null;
        this.$emit('changedDate',null);
      }
    }
  }
</script>
<style lang="scss">

  .date-picker{
    .vc-date{
      display: none;
    }

    .material-icons{
      cursor:pointer!important;
    }

    .right{
      right: 0;
    }
  }

</style>
